import React from 'react';
import Snowfall from 'react-snowfall'
import './App.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function FadeInSection(props: any) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    });
    observer.observe(domRef.current!)
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

function App() {
  const [openPopup, setOpenPopup] = React.useState(false);
  return (
    <>
        <Snowfall
        // Changes the snowflake color
        color="red"
        // Applied to the canvas element
        style={{ background: '#000', height: '1400px' }}        
        // Controls the number of snowflakes that are created (default 150)
        snowflakeCount={100}
        />

        <FadeInSection key={"beam"}>
          <div>
            <div className="px-5 lg:container mr-auto ml-auto flex flex-col justify-center relative w-full">
               <div className="flex justify-between z-10 mt-5 lg:mt-10 w-full">
                 <div className="flex items-center">
                   <img src="/header-logo.svg" />
                   {/* <a href='/tokenomics.pdf' target="_blank" rel='noreferrer'>
                    <div className='text-textPrimary text-sm font-bold transition hover:text-aqua cursor-pointer ml-14 hidden xl:block'>
                      TOKENOMICS
                    </div>
                  </a>
                  <a href='https://docs.Khaosswap.io/' target="_blank" rel='noreferrer'>
                    <div className='text-textPrimary text-sm font-bold transition hover:text-aqua cursor-pointer ml-6 hidden xl:block'>
                      DOCS
                    </div>
                  </a> */}
                 </div>
                 <div className="flex gap-4">
                 {/* <a className="text-white hover:bg-secondary text-center py-3 px-6 rounded-lg cursor-pointer transition hover:scale-105 m-auto semi border border-primary border-1 xl:block hidden" href='https://app.khaos.exchange/' target="_blank" rel='noreferrer'>                  
                     Buy $KHAOS           
                </a> */}
                <div className="flex gap-3 lg:gap-6 justify-center items-center lg:px-5 py-2">
                  <a href="https://twitter.com/KhaosExchange" target="_blank">
                    <img src="/twitterdark.svg" />
                  </a>                  
                  <a href="https://t.me/KhaosExchange" target="_blank">
                    <img src="/telegramdark.svg" />
                  </a>                  
                </div>
                <a className="text-white hidden md:block hover:bg-secondary text-center py-3 px-6 rounded-lg cursor-pointer transition hover:scale-105 m-auto semi bg-btn2" href='https://app.khaos.exchange' target={"_blank"} rel='noreferrer'>                  
                     Launch App        
                </a>

                {/* <a href="#birdy" className='text-white hidden md:block hover:bg-secondary text-center py-3 px-6 rounded-lg cursor-pointer transition hover:scale-105 m-auto semi bg-btn2' id="openPopUp">Launch App</a>

                <aside id="birdy" className="popup">
                    <div className="popUpContainer !rounded">
                       <header>
                        <a href="#!" className="closePopUp !text-white bg-btn2 rounded-xl !text-sm !px-3">X</a>                        
                       </header>
                      <article className='bg-secondaryDark p-5 rounded-2xl'>
                        <img src="/mascot.png" />
                        <div className='text-2md text-white font-bold w-full text-center'>Coming soon!</div>
                        <div className="flex gap-3 lg:gap-6 justify-center items-center lg:px-5 py-2 mt-3">
                          <a href="https://twitter.com/KhaosExchange" target="_blank">
                            <img src="/twitterdark.svg" />
                          </a>                  
                          <a href="https://t.me/KhaosExchange" target="_blank">
                            <img src="/telegramdark.svg" />
                          </a>                  
                        </div>
                      </article>
                   </div>        
                   <a href="#!" className="closePopUpOutSide"></a>    
                </aside> */}

                 </div>
               </div>               
               <img src="/bg.svg" className='mr-auto ml-auto right-0 left-0 -mb-48 lg:mb-20 -mt-10 xl:-mt-5'/>
               <div className="flex flex-col items-center lg:items-end gap-5 mt-32 lg:-mt-64 lg:mr-40 lg:absolute lg:bottom-[200px] xl:bottom-[350px] 2xl:bottom-[420px] -right-20 xl:right-20">
                 <div className="bg-liq p-2 rounded-lg lg:-mt-64 w-full lg:w-auto">
                  <div className="text-white text-3xl lg:text-6xl font-bold mx-10 text-center mb-5 lg:mb-0">Defi Hub On</div>
                  <div className="p-5 border border-primary border-1 mx-10 rounded-2xl bg-transparentFire">
                    <img className="lg:my-5 mr-auto ml-auto md:w-[300px]" src="/images/exn_white.png" />
                  </div>
                  <div className="text-white text-center mt-5">
                    Swap, bridge, earn and more. <br /> All in one place and simple to use
                  </div>
                  <a className="text-white block text-center py-3 px-6 rounded-lg text-2xl font-bold mb-5 cursor-pointer transition hover:scale-105 m-auto bg-btn2 !mx-10 mt-5" href="https://app.khaos.exchange" target={"_blank"} rel='noreferrer'>                  
                     Launch App
                  </a>
                  
                 </div>
               </div>
             </div>
          </div>
        </FadeInSection>

        {/* <FadeInSection key={"servicesff"}>
          <div style={{marginBottom: 300}}>
            <div className="px-5 lg:container mr-auto ml-auto flex flex-col items-center justify-center gap-10 w-full mt-64 xl:mt-32">          
                <div className="text-white text-5xl xl:text-6xl semi font-bold mb-14 xl:mt-64">Core features</div>
                <div className="flex flex-col xl:flex-row gap-10 w-full justify-center mx-10 xl:mx-0 rounded-3xl p-5 bg-transparentFire border border-primary border-1">
                  <a className="bg-secondary rounded-3xl flex flex-col items-center gap-3 xl:w-1/3 transition hover:scale-105 hover-btn2 cursor-pointer py-7 mx-5 xl:mx-0" href="https://app.khaos.exchange/" target="_blank" rel="noreferrer">                   
                      <img src="/arrow.png" width={90} height={90} />
                      <div className='text-md text-white semi'>Swap Tokens</div>                    
                  </a>
                  <a className="bg-secondary rounded-3xl flex flex-col items-center gap-3 xl:w-1/3 transition hover:scale-105 hover-btn2 cursor-pointer py-7 mx-5 xl:mx-0" href="https://app.khaos.exchange/exchange/pool" target="_blank" rel="noreferrer">                   
                      <img src="/supply.png" width={90} height={90} />
                      <div className='text-md text-white semi'>Supply Liquidity</div>                    
                  </a>
                  <a className="bg-secondary rounded-3xl flex flex-col items-center gap-3 xl:w-1/3 transition hover:scale-105 hover-btn2 cursor-pointer py-7 mx-5 xl:mx-0" href="https://app.khaos.exchange/farm" target="_blank" rel="noreferrer">                   
                      <img src="/yield.png" width={90} height={90} />
                      <div className='text-md text-white semi'>Yield Farms</div>                    
                  </a>
                </div>

                <div className='flex xl:flex-row flex-col gap-14 p-4 lg:p-10 bg-transparentFire border border-primary border-1 rounded-2xl'>
                <div className="flex flex-col gap-10 items-center hover:border-none rounded-3xl xl:w-1/3 group h-fit hover-btn2 bg-secondary transition xl:hover:scale-105 p-5 xl:p-10">
                  <div className="text-white text-2xl semi text-center">Beamshare token</div>
                  <img src="/1.svg" className='-mt-6' />
                  <div className=''>
                    <div className="text-white text-center">
                      When you stake your $GLINT in the single tokens as receipt, representing your share of the pool.
                    </div>
                    <a href="https://app.khaos.exchange/beamshare" target="_blank" rel="noreferrer">
                      <div className="text-white semi text-center w-full py-3 text-xl font-bold cursor-pointer tranisiton bg-btn2 hover:bg-secondary  mr-auto ml-auto rounded-xl mt-6">
                        Beamshare
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex flex-col gap-10 items-center hover:border-none rounded-3xl xl:w-1/3 group h-fit hover-btn2 bg-secondary transition xl:hover:scale-105 p-5 xl:p-10">
                  <div className="text-white text-2xl semi text-center">Integrated Bridge</div>
                  <img src="/2.svg" className='-mt-6 rounded-xl' />
                  <div className=''>
                    <div className="text-white text-center">
                      Bridge your cryptocurrencies from other EVM chains to Moonbeam and vice versa with Khaosswap bridge.
                    </div>
                    <a href="https://app.khaos.exchange/bridge" target="_blank" rel="noreferrer">
                    <div className="text-white semi text-center w-full py-3 text-xl font-bold cursor-pointer tranisiton bg-btn2 hover:bg-secondary  mr-auto ml-auto rounded-xl mt-6">
                        Use Bridge
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex flex-col gap-10 items-center hover:border-none rounded-3xl xl:w-1/3 group h-fit hover-btn2 bg-secondary transition xl:hover:scale-105 p-5 xl:p-10">
                  <div className="text-white text-2xl semi text-center">Variety of features</div>
                  <img src="/3.svg" className='-mt-6' />
                  <div className=''>
                    <div className="text-white text-center">
                      Limit orders, portfolio tracker, charts and more! Take advantage of the features that Khaosswap has!
                    </div>
                    <a href="https://app.khaos.exchange/" target="_blank" rel="noreferrer">
                    <div className="text-white semi text-center w-full py-3 text-xl font-bold cursor-pointer tranisiton bg-btn2 hover:bg-secondary  mr-auto ml-auto rounded-xl mt-6">
                        Khaosswap
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection> */}


        {/* <FadeInSection key={"servicess"}>
          <div style={{marginBottom: 300}}>
            <div className="px-5 lg:container mr-auto ml-auto items-center justify-center w-full mt-32 xl:mt-56 relative">          
              <img src="/roadmap-bg.svg" className='absolute hidden xl:block opacity-70 -mt-24' />
              <div className="text-white text-5xl xl:text-8xl semi font-bold text-center mb-14 xl:mb-44">Roadmap 2022</div>
              <div className='flex flex-col gap-10 xl:gap-0 w-full'>              

                <div className="flex flex-col xl:flex-row  justify-center xl:justify-between xl:w-1/2">
                  <div className='text-jordyBlue text-3xl semi text-center xl:hidden mb-3'>
                      Q1
                  </div>
                  <div className='p-10 relative border border-primary rounded-xl xl:w-2/3' style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 8px #314894'}}>
                    <img src="/line-right.svg" className='absolute hidden xl:block' style={{right: -166, top: 25}} />                    
                    <ul className='list-disc text-jordyBlue'>
                      <li>Khaosswap IDO</li>
                      <li>$GLINT Token generation event</li>
                      <li>Khaosswap launch on Moonbeam</li>
                      <li>DEX and AMM</li>
                      <li>Yield farming</li>
                      <li>Bridge</li>
                      <li>Liquidity locker</li>
                      <li>Faucet</li>
                      <li>Beamshare DEX Analytics</li>
                    </ul>
                  </div>
                  <div className='text-jordyBlue text-3xl semi w-1/3 text-right hidden xl:block'>
                    Q1
                  </div>
                </div>

                <div className="flex flex-col xl:flex-row  justify-center xl:justify-between xl:w-1/2 xl:ml-auto">
                  <div className='text-white text-3xl hidden xl:block semi w-1/3'>
                      Q2
                  </div>
                  <div className='text-white text-3xl semi text-center xl:hidden mb-3'>
                      Q2
                  </div>
                  <div className='p-10 relative border border-primary rounded-xl xl:w-2/3 bg-linear-gradient-border'>
                    <img src="/line-left.svg" className='absolute hidden xl:block' style={{left: -155, top: 25}} />                    
                    <ul className='list-disc text-jordyBlue'>
                      <li>Launchpad</li>
                      <li>First $GLINT Token burn</li>
                      <li>Zap</li>
                      <li>Syrup pools</li>
                      <li>Portfolio tracker</li>
                      <li>Website update and stability improvements</li>
                      <li>Stable AMM</li>
                      <li className='text-white'>Add support for more languages</li>
                      <li className='text-white'>Limit orders</li>
                    </ul>
                  </div>                  
                </div>

                <div className="flex flex-col xl:flex-row  justify-center xl:justify-between xl:w-1/2">
                  <div className='text-white text-3xl semi text-center xl:hidden mb-3'>
                      Q3/Q4
                  </div>
                  <div className='p-10 relative border border-primary rounded-xl xl:w-2/3 bg-linear-gradient-border-full'>
                    <img src="/line-right.svg" className='absolute hidden xl:block' style={{right: -110, top: 25}} />
                    <ul className='list-disc text-jordyBlue'>
                      <li>Yield farming V2</li>
                      <li>UI Update</li>
                      <li>Lending and borrowing</li>
                      <li>Team expansion</li>
                      <li>Advanced trading</li>
                      <li className='text-white'>Governance</li>
                      <li className='text-white'>CEX Listing</li>
                      <li className='text-white'>NFT Marketplace</li>
                      <li className='text-white'>Prediction markets</li>
                      <li className='text-white'>Lottery</li>
                    </ul>
                  </div>
                  <div className='text-white hidden xl:block text-3xl semi w-1/3 text-right'>
                    Q3/Q4
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection> */}

        {/* <FadeInSection key={"servicessss"}>
          <div style={{marginBottom: 300}}>
            <div className="px-5 lg:container mr-auto ml-auto flex items-center justify-center gap-10 w-full mt-32 xl:mt-56">          
                <img src="/token.svg" className="w-full hidden lg:block" />
                <img src="/token-mobile.svg" className="lg:hidden w-full rounded-2xl" />
            </div>
          </div>
        </FadeInSection> */}

        {/* <FadeInSection key={"servicesas"}>
          <div style={{marginBottom: 500}}>
            <div className="px-5 lg:container mr-auto ml-auto flex flex-col items-center justify-center gap-10 w-full mt-32 xl:mt-56">          
                <div className="text-secondary text-5xl xl:text-7xl semi font-bold mb-10 text-center">Some Trusted Partners</div>
                <div className="flex gap-10 w-full justify-center w-full items-center flex-wrap">
                    <a href="https://multichain.org/" target={"_blank"} className=" mr-auto ml-auto p-9 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/multichain.png" className='mr-auto ml-auto' width={150} height={150}/>
                    </a>
                    <a href="https://solidity.finance/" target={"_blank"} className=" mr-auto ml-auto p-4 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/solidity-finance.png" className='mr-auto ml-auto' width={60} height={60} />
                    </a>
                    <a href="https://blastapi.io/" target={"_blank"} className=" mr-auto ml-auto p-5 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/blast.png" className='mr-auto ml-auto' width={160} height={160}  />
                    </a>
                    <a href="https://bird.money/" target={"_blank"} className=" mr-auto ml-auto p-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/bird.png" className='mr-auto ml-auto' width={150} height={150} />
                    </a>
                    <a href="https://ola.finance/" target={"_blank"} className=" mr-auto ml-auto p-5 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/landing-partners-ola.png" className='mr-auto ml-auto' width={110} height={110} />
                    </a>
                    <a href="https://www.diadata.org/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/landing-partners-dia.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>




                    <a href="https://acala.network/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/acala.png" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://www.coinbase.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-9 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/coinbase.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://subwallet.app/" target={"_blank"} className=" mr-auto ml-auto p-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/subwallet.png" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://subsquid.io/" target={"_blank"} className=" mr-auto ml-auto p-6 py-8 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/subsquid.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://chain.link/" target={"_blank"} className=" mr-auto ml-auto p-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/chainlink.png" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://supraoracles.com/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/supraoracles.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>       


                    <a href="https://authtrail.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-8 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/authtrail.png" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://rubic.exchange/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/rubic.svg " className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://beefy.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/beefy.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://joinembr.com/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/embr.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://web.fractal.id/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/fractal.svg" className='mr-auto ml-auto' width={110} height={110} />
                    </a>

                    <a href="https://moonbeans.io/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                      <img src="/images/moonbeans.png" className='mr-auto ml-auto' width={110} height={110} />
                    </a>                                                                            
                </div>
            </div>
          </div>
        </FadeInSection> */}

        <FadeInSection key={"servicesd"}>
          <div style={{marginBottom: 50}}>
            <div className="px-5 lg:container mr-auto ml-auto flex items-center justify-center w-full mt-32 xl:mt-56">          
              <div className="w-full flex xl:flex-row flex-col justify-between z-10 items-start mx-10 xl:mx-0">

                <div className="flex flex-col xl:w-1/5 mb-10 xl:mb-0">
                  <img src="/header-logo.svg" width={178} height={34} />
                  <div className="my-6 text-white">
                    Decentralized exchange (DEX) with an automated market maker (AMM), providing liquidity and peer-to-peer transactions, built on the Exosama network.
                  </div>
                  <div className="flex gap-3 justify-start w-full">
                    <a href="https://twitter.com/KhaosExchange" target="_blank" rel='noreferrer'>
                       <img src="/twitter.svg.svg" className='brt-0 brb-0 footer-hover fs' width={30} height={30} />
                     </a> 
                     <a href="https://t.me/KhaosExchange" target="_blank" rel='noreferrer'>
                       <img src="/telegram.svg.svg" className='brt-0 brb-0 footer-hover fs' width={30} height={30} />
                     </a>                                
                  </div>
                </div>

                <div className="flex xl:flex-row flex-col gap-10">
                  <div>
                    <div className="text-white semi text-sm mb-6">Support</div>
                    <div className='flex flex-col gap-2'>
                      <a  className="text-metal hover:text-white cursor-pointer disabled" href='https://twitter.com/KhaosExchange' rel='noreferrer'>
                        Twitter
                      </a>
                      <a  className="text-metal hover:text-white cursor-pointer disabled" href='https://t.me/KhaosExchange' rel='noreferrer'>
                        Telegram
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="text-white semi text-sm mb-6">Khaos</div>
                    <div className='flex flex-col gap-2'>
                      <a  className="text-metal hover:text-white cursor-pointer" href='https://app.khaos.exchange' target="_blank" rel='noreferrer'>
                        Enter Khaos
                      </a>
                    </div>
                  </div>
                </div>

              </div>              
            </div>  
            {/* <div className="px-5 lg:container mr-auto ml-auto flex items-center justify-center w-full -mt-64">
            <img src="/footergraphic.svg" />
            </div>           */}
          </div>
        </FadeInSection>        
{/* 
        <FadeInSection>
          <div className="px-5 lg:container mr-auto ml-auto flex flex-col justify-center">
          </div>          
        </FadeInSection> */}
        
    </>
  );
}

export default App;
